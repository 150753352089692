import {
    TRYING_GET_USER,
    GET_USER_SUCCESS,
    GET_USER_ERROR,

    TRYING_GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,

    TRYING_CREATE_ACCOUNT,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_ERROR,

    TRYING_UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,

    TRYING_DELETE_ACCOUNT,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_ERROR,

    TRYING_GET_DATABASES,
    GET_DATABASES_SUCCESS,
    GET_DATABASES_ERROR,

    TRYING_UPDATE_IMAGE,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_ERROR,

    TRYING_GET_IMAGE,
    GET_IMAGE_SUCCESS,
    GET_IMAGE_ERROR,

    TRYING_GET_MEMBERSHIP,
    GET_MEMBERSHIP_SUCCESS,
    GET_MEMBERSHIP_ERROR,
    
    TRYING_SET_MEMBERSHIP,
    SET_MEMBERSHIP_SUCCESS,
    SET_MEMBERSHIP_ERROR,

    RESET_USER_CREATED
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';


export const reset_user_created = () => ({
    type:RESET_USER_CREATED,
    payload:true    
})

export function getImagen( userID ){
    return async ( dispatch ) => {
        dispatch( intentandoagetImagen() );
        try {
    
            const respuesta = await clienteAxios.get(`/users/getImage/${userID}`);
            // console.log('respuesta post client',respuesta.data)
            dispatch( agetImagenExito(respuesta.data) )


        } catch (error) {
            console.log(error)

            dispatch( agetImagenError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}
const intentandoagetImagen = () => ({
    type:TRYING_GET_IMAGE,
    payload:true    
})

const agetImagenExito = (image) => ({
    type:GET_IMAGE_SUCCESS,
    payload:image    
})

const agetImagenError = () => ({
    type:GET_IMAGE_ERROR,
    payload:true    
})

export function actualizarImagen( file , fileName){
    return async ( dispatch ) => {
        dispatch( intentandoactualizarImagen() );
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", fileName);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            console.log('formData',formData)
            const respuesta = await clienteAxios.post(`/users/image`,formData, config);
            // console.log('respuesta post client',respuesta)
            dispatch( actualizarImagenExito(respuesta.data) )

            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Exito',
                text:'Imagen subida con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( actualizarImagenError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }      
}

const intentandoactualizarImagen = () => ({
    type:TRYING_UPDATE_IMAGE,
    payload:true    
})

const actualizarImagenExito = (users) => ({
    type:UPDATE_IMAGE_SUCCESS,
    payload:users    
})

const actualizarImagenError = () => ({
    type:UPDATE_IMAGE_ERROR,
    payload:true    
})

export function obtenerUsuarios( db = null){
    return async ( dispatch ) => {
        dispatch( intentandoobtenerUsuarios() );
        try {
            const respuesta = await clienteAxios.get(`/users`,db);
            // console.log('respuesta post client',respuesta)
            dispatch( obtenerUsuariosExito(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( obtenerUsuariosError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}

const intentandoobtenerUsuarios = () => ({
    type:TRYING_GET_USERS,
    payload:true    
})

const obtenerUsuariosExito = (users) => ({
    type:GET_USERS_SUCCESS,
    payload:users    
})

const obtenerUsuariosError = () => ({
    type:GET_USERS_ERROR,
    payload:true    
})

export function actualizarCuenta( user ){
    return async ( dispatch ) => {
        dispatch( intentandoactualizarCuenta() );
        try {
            console.log('user',user)
            let respuesta;
            if(user.id){
                respuesta = await clienteAxios.put(`/users/${user.id}`,user);

            }else{

                respuesta = await clienteAxios.put(`/users/${user.new_account.id}`,user);
            }
            // console.log('respuesta post client',respuesta)
            dispatch( actualizarCuentaExito(respuesta.data) )
            Swal.fire({
                icon:'success',
                title:'Exito',
                text:'Datos Actualizados'
            })
        } catch (error) {
            console.log(error)

            dispatch( actualizarCuentaError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }       
}

const intentandoactualizarCuenta = () => ({
    type:TRYING_UPDATE_USER,
    payload:true    
})

const actualizarCuentaExito = () => ({
    type:UPDATE_USER_SUCCESS,
    payload:true    
})

const actualizarCuentaError = () => ({
    type:UPDATE_USER_ERROR,
    payload:true    
})

export function obtenerUsuario( usuario_id ){
    return async ( dispatch ) => {
        dispatch( intentandoObtenerUsuario() );
        try {
            const respuesta = await clienteAxios.get(`/users/${usuario_id}`);
            // console.log('respuesta post client',respuesta)
            dispatch( obtenerUsuarioExito(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( obtenerUsuarioError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}   

const intentandoObtenerUsuario = () => ({
    type:TRYING_GET_USER,
    payload:true
})

const obtenerUsuarioExito = ( user ) => ({
    type:GET_USER_SUCCESS,
    payload:user
})

const obtenerUsuarioError = (error) => ({
    type:GET_USER_ERROR,
    payload:error    
})

export function createAccount( datos , history){
    return async ( dispatch ) => {
        dispatch( intentandocreateAccount() );
        try {
            let respuesta;
            if(datos.new_account.fromadmin){
                respuesta = await clienteAxios.post(`/users/admin_create_account`,datos);
            }else if(datos.new_account.captcha){
                respuesta = await clienteAxios.post(`/users/create_account_withouttoken`,datos);
            }else{
                respuesta = await clienteAxios.post(`/users/create_account`,datos);
            }
            // console.log('respuesta post client',respuesta)
            dispatch( createAccountExito(respuesta.data) )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'La cuenta ha sido creada con éxito'
            })
            if(history){
                history.push('/admin_accounts')
            }

        } catch (error) {
            // console.log(error.response.data.message)

            dispatch( createAccountError(error.message) );

            //alerta de error
            if(error.response.data){
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:error.response.data.message
                })

            }else{
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:error.message
                })
            }
        }
    }   
}   

const intentandocreateAccount = () => ({
    type:TRYING_CREATE_ACCOUNT,
    payload:true
})

const createAccountExito = ( user ) => ({
    type:CREATE_ACCOUNT_SUCCESS,
    payload:user
})

const createAccountError = (error) => ({
    type:CREATE_ACCOUNT_ERROR,
    payload:error    
})

export function deleteAccount( account_id ){
    return async ( dispatch ) => {
        dispatch( intentandodeleteAccount() );
        try {
            const respuesta = await clienteAxios.delete(`/users/${account_id}`);
            // console.log('respuesta post client',respuesta)
            dispatch( deleteAccountExito(respuesta.data) )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'La cuenta ha sido borrada con éxito'
            })
        } catch (error) {
            // console.log(error.response.data.message)

            dispatch( deleteAccountError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:error.response.data.message
            })
        }
    }   
}   

const intentandodeleteAccount = () => ({
    type:TRYING_DELETE_ACCOUNT,
    payload:true
})

const deleteAccountExito = ( user ) => ({
    type:DELETE_ACCOUNT_SUCCESS,
    payload:user
})

const deleteAccountError = (error) => ({
    type:DELETE_ACCOUNT_ERROR,
    payload:error    
})

export function getDatabases(){
    return async ( dispatch ) => {
        dispatch( intentandogetDatabases() );
        try {
            const respuesta = await clienteAxios.post(`/users/databases`);
            // console.log('respuesta post client',respuesta)
            dispatch( getDatabasesExito(respuesta.data) )
            //alerta de error
            
        } catch (error) {
            // console.log(error.response.data.message)

            dispatch( getDatabasesError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:error.response.data.message
            })
        }
    }   
}   

const intentandogetDatabases = () => ({
    type:TRYING_GET_DATABASES,
    payload:true
})

const getDatabasesExito = ( data ) => ({
    type:GET_DATABASES_SUCCESS,
    payload:data
})

const getDatabasesError = (error) => ({
    type:GET_DATABASES_ERROR,
    payload:error    
})


export function getMembership( dbname ){
    return async ( dispatch ) => {
        dispatch( intentandogetMembership() );
        try {
    
            const respuesta = await clienteAxios.get(`/users/getmembership/${dbname}`);
            // console.log('respuesta post client',respuesta)
            dispatch( getMembershipExito(respuesta.data) )


        } catch (error) {
            console.log(error)

            dispatch( getMembershipError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}
const intentandogetMembership = () => ({
    type:TRYING_GET_MEMBERSHIP,
    payload:true    
})

const getMembershipExito = (image) => ({
    type:GET_MEMBERSHIP_SUCCESS,
    payload:image    
})

const getMembershipError = () => ({
    type:GET_MEMBERSHIP_ERROR,
    payload:true    
})

export function setMembership( data ){
    return async ( dispatch ) => {
        dispatch( intentandosetMembership() );
        try {
    
            const respuesta = await clienteAxios.post(`/users/setmembership`,data);
            // console.log('respuesta post client',respuesta)
            dispatch( setMembershipExito(respuesta.data) )


        } catch (error) {
            console.log(error.response.data)

            dispatch( setMembershipError(error.response.data.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:error.response.data.message
            })
        }
    }  
}
const intentandosetMembership = () => ({
    type:TRYING_SET_MEMBERSHIP,
    payload:true    
})

const setMembershipExito = (image) => ({
    type:SET_MEMBERSHIP_SUCCESS,
    payload:image    
})

const setMembershipError = (error) => ({
    type:SET_MEMBERSHIP_ERROR,
    payload:error    
})