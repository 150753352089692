import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerPlantilla, crearPlantillaAction } from '../../actions/plantillasActions';
import Select from 'react-select';
import $ from 'jquery'

export const LayoutEdit = () => {

    const [LayoutId, setLayoutId] = useState(null);
    const dispatch = useDispatch()

    const loading = useSelector( state => state.plantillas.loading )
    const error = useSelector( state => state.plantillas.error )
    const plantillaSearch = useSelector( state => state.plantillas.layoutEdit )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [ plantilla, setPlantilla ] = useState({
        id:null,
        nombre:"",
        descripcion:"",
        tipo:""
    })
    var options = [
        {  value: "texto", label: "texto" },
        {  value: "html", label: "html" }
    ];
    // const {nombre, descripcion, tipo} = plantilla


    useEffect( () => {
        const idTrozos = window.location.pathname.split('/');
        if(idTrozos.length == 3){
            setLayoutId(idTrozos[2]);
        }
        if(LayoutId && plantillaSearch === null){
            console.log('LayoutId',LayoutId)
            dispatch(obtenerPlantilla(LayoutId))
        }
        if(plantillaSearch){
            setPlantilla(plantillaSearch);
        }
        
if(search_custom_page == null){
    $('#loading').hide();
  }
    },[LayoutId,plantillaSearch,search_custom_page])

    const handleChange = (e) => {
        setPlantilla({
            ...plantilla,
            [e.target.name]: e.target.value
        })
    }  
    const setTipo = (e) => {
  
        setPlantilla({
            ...plantilla,
            tipo: e.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if(plantilla.nombre.trim() === "" || plantilla.descripcion.trim() === ""){
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Los campos nombre y descripción son obligatorios'
            })
            return;
        }
        dispatch(crearPlantillaAction(plantilla));
        
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Actualizar Plantilla</span>
            </div>
            
            <div className="col-md-4 ml-3">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Nombre</label>
                    <input type="text" className="form-control"  name="nombre" value={plantilla.nombre} onChange={handleChange}/>
                </div>
                <div className="form-group">
                    <label>Descripción</label>
                    <textarea className="form-control"   name="descripcion" value={plantilla.descripcion} onChange={handleChange}> </textarea>
                </div>
                <div className="form-group">
                    <label>Tipo</label>
                    
                    <Select
                        name="tipo"
                        defaultValue={{label:plantilla.tipo, value:plantilla.tipo}}
                        value={{label:plantilla.tipo, value:plantilla.tipo}}
                        options={options}
                        onChange={setTipo}
                        
                    />
                </div>
                <input type="submit" value="Actualizar Plantilla" className="btn btn-primary"/>

                <Link to="/layoutlist" className="ml-4 colorsito">Volver</Link>
            </form>
            </div>
        </div>
    )
}
