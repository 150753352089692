import React, {useEffect} from 'react'
import { Link, useParams, useHistory  } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { obtenerPlantillasDe } from '../../actions/plantillasActions';
import { setLayout } from '../../actions/listasActions';
import $ from 'jquery'
import { get_marketing_designs } from '../../actions/campaignsActions';
import { BreadCrumbCampaign } from '../../components/BreadCrumbCampaign';


export const CampaignHtml = () => {
    const { idCampaign } = useParams()
    const dispatch = useDispatch()
    const plantillas = useSelector( state => state.plantillas.plantillas )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )
    const designs = useSelector( state => state.campaigns.designs )


    let historyHook = useHistory();
    const idTrozos = window.location.pathname.split('/');

    useEffect( async () => {
        await dispatch( obtenerPlantillasDe('html') );
        await dispatch( get_marketing_designs());
  
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[search_custom_page]);

    const handleClick = (allObject) => {
        dispatch(setLayout(allObject))
        historyHook.push(`/campaign_editor/${idCampaign}/${idTrozos[2]}`);
    }

    
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
            <BreadCrumbCampaign active={3}/>

                <span className="mr-3 naranja">Editor visual</span>
            </div>
            <div className="btn-group ml-4">
                <Link to={`/campaign_design/${idCampaign}`} className="colorsito">Volver</Link>
            </div>
            <div className="row text-center">
            <div className="row mt-5">
                <div className="col-xs-6 col-md-3">
                    <div class="card" style={{"width": "12rem"}} onClick={() => handleClick( "blanco" ) }>
                        <img src={`https://img.freepik.com/vector-gratis/plantilla-cartel-blanco-blanco-marco-negro-affiche-hoja-papel_172533-326.jpg`} className="card-img-top" alt="User "/>
                        <div class="card-body">
                            <p class="card-text">En Blanco</p>
                        </div>
                    </div>
                  
                </div>
                {
                    plantillas && plantillas.length && plantillas.map( plantilla => (
                        plantilla.contenido ? <div className="col-xs-6 col-md-3" key={plantilla.id}>
                            <div class="card hover" style={{"width": "12rem"}}   onClick={ () => handleClick(plantilla) }>
                            <div style={{"height": "210,66px","width":"241,27px"}}></div>
                            <div class="card-body">
                                <p class="card-text">{plantilla.nombre}</p>
                            </div>
                            </div>

                        </div> : null
                    ))
                }
                {
                    designs && designs.length ? designs.map( design => (
                        design.html ? <div className="col-xs-6 col-md-3" key={design.id}>
                            <div class="card hover" style={{"width": "12rem"}}  onClick={ () => handleClick(design) }>
                                <img src={`${process.env.REACT_APP_BACKEND}/campaigns/get_image_template/${design.id}`} className="card-img-top" alt="User "/>
                                
                                <div class="card-body">
                                    <b class="card-text">{design.categoria}</b>
                                    <p class="card-text">{design.nombre}</p>
                                </div>
                            </div>
                           
                         </div>: null
                    )) : <span>Cargando diseños disponibles... ...</span>
                }
            </div>
            </div>
        </div>
    )
}
