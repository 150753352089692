import React, { useEffect, useState } from 'react'
// redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerUsuario,actualizarImagen, getImagen, actualizarCuenta } from '../../actions/usersActions';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import $ from 'jquery'

export const Perfil = () => {
    const d = new Date();

    const dispatch = useDispatch()
    const [busco,setBusco] = useState(false);

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [img, setImg] = useState(null);
  
    

    const [currentUser, setCurrentUser] = useState({
        nombre:'',
        email:'',
        telefono:'',
        password:''
    });
    const user = useSelector( state => state.auth.user )
    const loading = useSelector( state => state.auth.loading )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )


    useEffect( async () => {
        if(busco === false){

            if(user){
                console.log('user',user)
                setCurrentUser({
                    id:user.id,
                    nombre:user.name,
                    email:user.email,
                    telefono:user.telefono
                });

                setImg(`${process.env.REACT_APP_BACKEND}/users/getImage/${user.id}`)
            }

            setBusco(true);
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[user,search_custom_page])
   
    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const handleChange = (e) => {
        setCurrentUser({
            ...currentUser,
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = (e) => {
       e.preventDefault();
    //    console.log('submit');
        const u = {
            id : user.id,
            nombre:currentUser.nombre,
            telefono:currentUser.telefono,
            password:currentUser.password
        }
        dispatch(actualizarCuenta({new_account:u}))
    }

  

    const onFormSubmit = async (e) => {
        e.preventDefault();
        await dispatch(actualizarImagen(file,fileName))
        setTimeout( () => {
            const d = new Date();

            setImg(`${process.env.REACT_APP_BACKEND}/users/getImage/${user.id}?${d.getTime()}`)
        },1000)
    }



    return (
        <div className="white_box QA_section card_height_100">
            <h4 className="p-3 naranja">Perfil</h4>
            {
                loading ? <LoaderSpinner/> : null
            }
            
            <div className="row mt-5 mb-5">
                <div className="col-md-4">
                   
                    {
                        user ? 
                        <img src={`${process.env.REACT_APP_BACKEND}/users/getImage/${user.id}?${d.getTime()}`} className="img-circle elevation-2 mb-5"  width="250" alt="User "/>
                        : <img src={`${process.env.REACT_APP_USER_IMAGE}`} className="img-circle elevation-2 mb-5" width="250" alt="User "/> 
                    }
                    
                    <form onSubmit={onFormSubmit}>
                        <div className="form-group">
                            <label>Subir Imagen</label><br></br>
                            <input type="file" name="myImage" onChange={saveFile} />
                        </div>
                        <button type="submit" className="btn btn-success">Subir</button>
                    </form>
                </div>
                <div className="col-md-4">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Nombre</label>
                            <input type="text" name="nombre" value={currentUser.nombre} onChange={ handleChange }  className="form-control-lg form-control"/>
                        </div>
                        <div className="form-group">
                            <label>Teléfono</label>
                            <input type="text" name="telefono" value={currentUser.telefono} onChange={ handleChange }  className="form-control-lg form-control"/>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="email" value={currentUser.email} onChange={ handleChange }  className="form-control-lg form-control"/>
                        </div>
                        <div className="form-group">
                            <label>Contraseña</label>
                            <input type="password" name="password" value={currentUser.password} onChange={ handleChange }  className="form-control-lg form-control"/>
                        </div>
                        <input type="submit" value="Actualizar" className="btn btn-success" />
                    </form>
                </div>
            </div>
        </div>
    )
}
