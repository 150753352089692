import {
    BEGIN_LIST_CONTACTFIELDS,
    LIST_CONTACTFIELDS_SUCCESS,
    LIST_CONTACTFIELDS_ERROR,

    BEGIN_LIST_CLIENTSFIELDS,
    LIST_CLIENTSFIELDS_SUCCESS,
    LIST_CLIENTSFIELDS_ERROR,

    BEGIN_UPDATE_CLIENTSFIELDS,
    UPDATE_CLIENTSFIELDS_SUCCESS,
    UPDATE_CLIENTSFIELDS_ERROR,

    BEGIN_UPDATE_CONTACTSFIELDS,
    UPDATE_CONTACTSFIELDS_SUCCESS,
    UPDATE_CONTACTSFIELDS_ERROR
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function obtenerContactFieldsAction(){
    return async ( dispatch ) => {
        dispatch( descargarFields() );
        try {
            // contacts_fields;
            // clients_fields;
            const respuesta = await clienteAxios.get(`/contacts_fields`);

            dispatch( descargaFieldsExitosa(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( descargaFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const descargarFields = () => ({
    type:BEGIN_LIST_CONTACTFIELDS,
    payload:true
})

const descargaFieldsExitosa = (FIELDS) => ({
    type:LIST_CONTACTFIELDS_SUCCESS,
    payload:FIELDS
})

const descargaFieldsError = () => ({
    type:LIST_CONTACTFIELDS_ERROR,
    payload:true   
})

export function obtenerClientsFieldsAction(){
    return async ( dispatch ) => {
        dispatch( descargarClientsFields() );
        try {
            // contacts_fields;
            // clients_fields;
            const respuesta = await clienteAxios.get(`/clients_fields`);

            dispatch( descargaClientsFieldsExitosa(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( descargaClientsFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const descargarClientsFields = () => ({
    type:BEGIN_LIST_CLIENTSFIELDS,
    payload:true
})

const descargaClientsFieldsExitosa = (FIELDS) => ({
    type:LIST_CLIENTSFIELDS_SUCCESS,
    payload:FIELDS
})

const descargaClientsFieldsError = () => ({
    type:LIST_CLIENTSFIELDS_ERROR,
    payload:true   
})

export function actualizarClientsFieldsAction( clientsFieds ){
    return async ( dispatch ) => {
        dispatch( actualizarClientsFields() );
        try {
            // contacts_fields;
            // clients_fields;
            const respuesta = await clienteAxios.put(`/clients_fields`,clientsFieds);

            dispatch( actualizarClientsFieldsExitosa(respuesta.data) )
            
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'El campo se actualizó con éxito'
            })
        } catch (error) {
            console.log(error)

            dispatch( actualizarClientsFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const actualizarClientsFields = () => ({
    type:BEGIN_UPDATE_CLIENTSFIELDS,
    payload:true
})

const actualizarClientsFieldsExitosa = (FIELDS) => ({
    type:UPDATE_CLIENTSFIELDS_SUCCESS,
    payload:FIELDS
})

const actualizarClientsFieldsError = () => ({
    type:UPDATE_CLIENTSFIELDS_ERROR,
    payload:true   
})

export function actualizarContactosFieldsAction( contactsFieds ){
    return async ( dispatch ) => {
        dispatch( actualizarContactsFields() );
        try {
            // contacts_fields;
            // clients_fields;
            const respuesta = await clienteAxios.put(`/contacts_fields`,contactsFieds);

            dispatch( actualizarContactsFieldsExitosa(respuesta.data) )

            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'El campo se actualizó con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( actualizarContactsFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const actualizarContactsFields = () => ({
    type:BEGIN_UPDATE_CONTACTSFIELDS,
    payload:true
})

const actualizarContactsFieldsExitosa = (FIELDS) => ({
    type:UPDATE_CONTACTSFIELDS_SUCCESS,
    payload:FIELDS
})

const actualizarContactsFieldsError = () => ({
    type:UPDATE_CONTACTSFIELDS_ERROR,
    payload:true   
})