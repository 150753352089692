import {
    GETTING_LAYOUT,
    GETTING_LAYOUT_SUCCESS,
    GETTING_LAYOUT_ERROR,

    CREATING_LAYOUT,
    CREATING_LAYOUT_SUCCESS,
    CREATING_LAYOUT_ERROR,

    SEARCH_LAYOUT,
    SEARCH_LAYOUT_SUCCESS,
    SEARCH_LAYOUT_ERROR,

    SET_LAYOUT_TO_EDIT,

    TRY_GET_LAYOUTS_TEXT,
    GET_LAYOUTS_TEXT_SUCCESS,
    GET_LAYOUTS_TEXT_ERROR,

    DELETING_LAYOUT,
    DELETING_LAYOUT_SUCCESS,
    DELETING_LAYOUT_ERROR
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function setLayoutToEdit( layout ){
    return async ( dispatch ) => {
        console.log('que paso',layout)
        dispatch( setLayoutToEditReducer(layout) )
    }    
}

const setLayoutToEditReducer = (layout) => ({
    type:SET_LAYOUT_TO_EDIT,
    payload:layout      
})

export function obtenerPlantillasDe(tipo){
    return async ( dispatch ) => {
        dispatch( tryobtenerPlantillasDeTexto() );
        try {
            const respuesta = await clienteAxios.get(`/layouts/?tipo=${tipo}`);

            dispatch( obtenerPlantillasDeTextoSuccess(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( obtenerPlantillasDeTextoError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    } 
}

const tryobtenerPlantillasDeTexto = () => ({
    type:TRY_GET_LAYOUTS_TEXT,
    payload:true     
})

const obtenerPlantillasDeTextoSuccess = (layouts) => ({
    type:GET_LAYOUTS_TEXT_SUCCESS,
    payload:layouts     
})

const obtenerPlantillasDeTextoError = (error) => ({
    type:GET_LAYOUTS_TEXT_ERROR,
    payload:error     
})

export function obtenerPlantilla( plantilla_id ){
    return async ( dispatch ) => {
        dispatch( buscarPlantilla() );
        try {
            const respuesta = await clienteAxios.get(`/layouts/${plantilla_id}`);

            dispatch( busquedaPlantillaSuccess(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( busquedaPlantillaError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }    
}

const buscarPlantilla = () => ({
    type:SEARCH_LAYOUT,
    payload:true      
})

const busquedaPlantillaSuccess = ( plantilla ) => ({
    type:SEARCH_LAYOUT_SUCCESS,
    payload:plantilla         
})

const busquedaPlantillaError = () => ({
    type:SEARCH_LAYOUT_ERROR,
    payload:true        
})


export function crearPlantillaAction( plantilla ){
    return async ( dispatch ) => {
        dispatch( haciendoPostPlantilla() );
        try {
            const respuesta = await clienteAxios.post('/layouts', plantilla );

            await dispatch( postPlantillaSuccess(respuesta.data) )

            //alerta de success
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Plantilla creada con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( postPlantillaError(error) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const haciendoPostPlantilla = () => ({
    type:CREATING_LAYOUT,
    payload:true
})

const postPlantillaSuccess = ( plantilla ) => ({
    type:CREATING_LAYOUT_SUCCESS,
    payload:plantilla
})

const postPlantillaError = ( error ) => ({
    type:CREATING_LAYOUT_ERROR,
    payload:error
})

export function obtenerPlantillasAction(){
    return async ( dispatch )=> {
        dispatch( obteniendoPlantillas() );
        try {
            const respuesta = await clienteAxios.get(`/layouts` );
            console.log('respuesta.data',respuesta.data)
            dispatch( obteniendoPlantillasSuccess(respuesta.data) )


        } catch (error) {
            console.log(error)

            dispatch( obteniendoPlantillasError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }    
}

const obteniendoPlantillas = () => ({
    type:GETTING_LAYOUT,
    payload:true     
})

const obteniendoPlantillasSuccess = ( plantillas ) => ({
    type:GETTING_LAYOUT_SUCCESS,
    payload:plantillas     
})

const obteniendoPlantillasError = () => ({
    type:GETTING_LAYOUT_ERROR,
    payload:true     
})

export function borrarPlantilla(id){
    return async ( dispatch )=> {
        dispatch( intentandoborrarPlantilla() );
        try {
            const respuesta = await clienteAxios.delete(`/layouts/${id}`);
            console.log('respuesta.data',respuesta.data)
            dispatch( borrarPlantillaSuccess(respuesta.data) )
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Plantilla borrada con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( borrarPlantillaError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }    
}

const intentandoborrarPlantilla = () => ({
    type:DELETING_LAYOUT,
    payload:true     
})

const borrarPlantillaSuccess = (  ) => ({
    type:DELETING_LAYOUT_SUCCESS,
    payload:true     
})

const borrarPlantillaError = () => ({
    type:DELETING_LAYOUT_ERROR,
    payload:true     
})
