import React, { useState, useEffect } from 'react'
import { Link, useHistory   } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { crearPlantillaAction } from '../../actions/plantillasActions';
import Select from 'react-select';
// import 'react-select/dist/css/react-select.css';
export const LayoutCreate = () => {
    const dispatch = useDispatch()
    let history = useHistory();
    const loading = useSelector( state => state.plantillas.loading )
    const layoutEdit = useSelector( state => state.plantillas.layoutEdit )
    const new_plantilla = useSelector( state => state.plantillas.plantilla )
    const [ plantilla, setPlantilla ] = useState({
        nombre:"",
        descripcion:"",
        tipo:""
    })
    var options = [
        { value: 'texto', label: 'Texto' },
        { value: 'html', label: 'Html' }
    ];

    const {nombre, descripcion, tipo} = plantilla

    const handleChange = (e) => {
        if(typeof e.target == 'undefined'){
            console.log(e)
            setPlantilla({
                ...plantilla,
                "tipo": e.value
            })
        }else{
            setPlantilla({
                ...plantilla,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(nombre.trim() === "" || descripcion.trim() === ""){
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Los campos nombre y descripción son obligatorios'
            })
            return;
        }

        await dispatch(crearPlantillaAction(plantilla));

        setPlantilla({
            nombre:"",
            descripcion:"",
            tipo:""
        })
        
    }
   
    useEffect(() => {
        
        if(layoutEdit){
            history.push(`/layout_editor/${layoutEdit.id}`)
        }
    }, [layoutEdit])
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Crear Plantilla</span>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <form onSubmit={handleSubmit} className="col-md-4 ml-3">
                <div className="form-group">
                    <label>Nombre</label>
                    <input type="text" className="form-control-lg form-control" required name="nombre" value={nombre} onChange={handleChange}/>
                </div>
                <div className="form-group">
                    <label>Descripción</label>
                    <textarea className="form-control-lg form-control" required name="descripcion" value={descripcion} onChange={handleChange}> </textarea>
                </div>
                <div className="form-group">
                    <label>Tipo</label>
                    <Select
                        name="tipo"
                        defaultValue={plantilla.tipo}
                        options={options}
                        onChange={handleChange}
                    />
                </div>
                <input type="submit" value="Crear Plantilla" className="btn btn-primary"/>
                <Link to="/layoutlist" className="ml-4 colorsito">Volver</Link>
            </form>
        </div>
    )
}
